import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from './current-user.service';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  constructor(private httpClient: HttpClient, private userService: CurrentUserService) { }

  public save(code: string) {
    return this.userService.currentUser$.pipe(
        switchMap(user => this.httpClient.post(`${environment.api}/business/${user.currentBusiness.id}/payment-account`, {code}, {headers: {'session-id': user.sessionId}}))
    );
  }
}
