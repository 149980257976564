import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService, User } from '../services/current-user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private currentUserService: CurrentUserService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this
      .currentUserService.currentUser$
      .pipe(
        map((currentUser: User) => {
          return !!currentUser && !!currentUser.currentBusiness && !!currentUser.currentBusiness.id;
        })
      );
  }
}
