import { NgModule } from '@angular/core';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatDividerModule, MatExpansionModule,
  MatFormFieldModule,
  MatIconModule, MatIconRegistry, MatInputModule, MatListModule, MatMenuModule,
  MatOptionModule, MatProgressSpinnerModule, MatRadioModule,
  MatRippleModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatTableModule, MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';

const listOfModules = [
  FlexLayoutModule,
  MatCardModule,
  MatToolbarModule,
  MatIconModule,
  MatRippleModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatDividerModule,
  MatTableModule,
  MatRadioModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatTooltipModule,
  MatExpansionModule,
  MatTabsModule,
  MatSidenavModule,
  MatListModule,
];

@NgModule({
  declarations: [],
  imports: [
    ...listOfModules,
  ],
  exports: [
    ...listOfModules,
  ]
})
export class CustomMaterialModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
