import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { RouterModule } from '@angular/router';
import { ItemComponent } from './item/item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { PayoutMethodDialogComponent } from './payout-method-dialog/payout-method-dialog.component';
import { SuccessIntegrationComponent } from './payout-method-dialog/success-integration/success-integration.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ShippingPriceListOverrideComponent } from './override-shipping-price-list/shipping-price-list-override.component';
import { ShippingPriceRowOverrideComponent } from './override-shipping-price-list/shipping-price-row/shipping-price-row-override.component';
import { SearchComponent } from './search/search.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';

const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: false
};

@NgModule({
  declarations: [
    ToolbarComponent,
    ItemComponent,
    PayoutMethodDialogComponent,
    SuccessIntegrationComponent,
    LoadingSpinnerComponent,
    ShippingPriceListOverrideComponent,
    ShippingPriceRowOverrideComponent,
    SearchComponent,
    EmptyStateComponent,
  ],
  exports: [
    ToolbarComponent,
    ItemComponent,
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    NgxCurrencyModule,
    LoadingSpinnerComponent,
    ShippingPriceListOverrideComponent,
    ShippingPriceRowOverrideComponent,
    SearchComponent,
    EmptyStateComponent,
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
  ],
  entryComponents: [
    PayoutMethodDialogComponent,
  ]
})
export class SharedModule { }
