import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountRequiredEstoreSetting, AccountService } from '../services/account.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingGuard implements CanLoad {

  constructor(private accountService: AccountService, private router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService.getAccountRequiredEstoreSetting().pipe(
      map( (value: AccountRequiredEstoreSetting) => {
        if (!!value.accountTypeId && !!value.countryId) {
          return true;
        }

        this.router.navigate(['/missing-account-information'], { queryParams: { returnUrl: segments[0].toString(), failureUrl: document.referrer }});
        return false;
      })
    );
  }
}
