import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../account';
import { CurrentUserService } from './current-user.service';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface SetAccountRequiredEstoreSetting {
  accountTypeId?: number;
  countryId?: number;
}

export interface AccountRequiredEstoreSetting {
  accountType?: string;
  accountTypeId?: number;
  country?: string;
  countryId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private httpClient: HttpClient, private userService: CurrentUserService) { }

  searchAccount(query: string): Observable<Account[]> {
    return this.userService.currentUser$.pipe(
      switchMap((user) => this.httpClient.get<Account[]>(
        `${environment.api}/business/search?query=${query}`,
        {headers: {'session-id': user.sessionId}}
      ))
    );
  }

  setAccountRequiredEstoreSetting(data: SetAccountRequiredEstoreSetting): Observable<{}> {
    return this.userService.currentUser$.pipe(
      switchMap((user) => this.httpClient.post(
        `${environment.api}/business/${user.currentBusiness.id}/account-required-estore-setting`,
        data,
        {headers: {'session-id': user.sessionId}}
      ))
    );
  }

  getAccountRequiredEstoreSetting(): Observable<AccountRequiredEstoreSetting> {
    return this.userService.currentUser$.pipe(
      switchMap((user) => this.httpClient.get<AccountRequiredEstoreSetting>(
        `${environment.api}/business/${user.currentBusiness.id}/account-required-estore-setting`,
        {headers: {'session-id': user.sessionId}}
      ))
    );
  }
}
