import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { StripeService } from '../../../core/services/stripe.service';

@Component({
  selector: 'app-success-integration',
  templateUrl: './success-integration.component.html',
  styleUrls: ['./success-integration.component.scss']
})
export class SuccessIntegrationComponent implements OnInit {
  private redirectUrl: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    this.route
      .queryParamMap
      .pipe(
        tap( params => this.redirectUrl = params.get('state')),
        map(params => params.get('code')),
        switchMap( code => this.stripeService.save(code))
      ).subscribe( (resp) => this.router.navigateByUrl(this.redirectUrl));
  }

}
