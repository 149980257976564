export const environment = {
  production: false,
  stripeKey: 'pk_test_eWWGPTA8a7rCLscPrzMIgpY700OUS5LHLc',
  api: 'https://dev-core-api.mymedquarters.com',
  backendUrl: 'https://dev-core-api.mymedquarters.com',
  portalUrl: 'https://estore.vodoland.com',
  loginUrl: 'https://login.vodoland.com',
  estoreUrl: 'https://estore.vodoland.com',
  pusherId: 'e7509741b811cb5a9304',
  orderUrl: 'order',
  stripe: {
    clientId: 'ca_F4UVRi7Qor5hYTZV9SIwhzqBmvTlOv4M',
    state: 'state'
  }
};
