import { Injectable } from '@angular/core';
import AvaCAS from 'ava-cas-iframe';
import { environment } from '../../../environments/environment';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface Business {
  id: string;
  name: string;
  logo: {
    id: string
  } | null;
}

export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  avatar: string;
  accountId: string;
  sessionId: string;
  currentBusiness: Business;
  actualCustomerPortalUrl: string;
  actualCustomerInstanceManagerUrl: string;
  orderCustomerPortalUrl: string;
  instanceName: string;
  instanceId: string;
  currentInstanceName: string;
  currentInstanceId: string;
  storeAvailable: boolean;
}

export interface InstanceLogin {
  token: string;
  currentUser: User;
  urls: any;
  guiSettings: any;
  instanceId: string;
  storeAvailable: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private cas: AvaCAS;
  currentUser$: Observable<User>;

  constructor(private httpClient: HttpClient) {
    this.cas = new AvaCAS(document, {
      src: this.getActualUrl(),
      postMessageSrc: this.getActualUrl(),
      usersUrl: environment.backendUrl,
      pusher: {
        id: environment.pusherId,
        cluster: 'mt1',
      },
    });
    this.currentUser$ = from(this.cas.init().then((data) => data)).pipe(
      tap((result) => {
        if (!('token' in result)) {
          window.location.replace(environment.loginUrl);
        }
      }),
      map((result: InstanceLogin) => ({...result, sessionId: result.token})),
      switchMap((result: InstanceLogin) => {
        return combineLatest(
          this.toolbarData(),
          of(result),
          this.httpClient.get<{id: string}[]>(
            `${environment.api}/user/${result.currentUser.id}/own-business`,
            {headers: {'session-id': result.token}}
          )
        );
      }),
        switchMap(([first, second, ownAccounts]) => {
          console.log(first, second, ownAccounts, first.currentUser.sessionId, first.business.id);
          return combineLatest(
            of(first),
            of(second),
            of(ownAccounts),
            this.httpClient.get<{id: string, name: string}[]>(
              `${environment.api}/business/${first.business.id}/instance`,
              {headers: {'session-id': second.token}}
            )
          );
        }),
        map(([first, second, ownAccounts, myInstances]) => {
        console.log(myInstances);
        const isBusinessOwner = ownAccounts.filter((ownAccount) => ownAccount.id === first.business.id).length > 0;

        if (!isBusinessOwner) {
          console.error(`User ${first.currentUser.id} is not business ${first.business.id} redirect to instance`);
          window.location.replace(`//${second.urls.instance}`);
        }

        if (!second.storeAvailable) {
          // window.location.replace(`//${second.urls.instance}`);
        }

        const url = window.location.hostname.split('.');
        url[0] = environment.orderUrl;

        return {
          ...first.currentUser,
          sessionId: second.token,
          actualCustomerPortalUrl: second.urls.instance,
          actualCustomerInstanceManagerUrl: second.urls.InstanceManager,
          instanceName: myInstances[0] ? myInstances[0].name : '',
          instanceId: myInstances[0] ? myInstances[0].id : '',
          currentInstanceName: second.guiSettings.theme.title,
          currentInstanceId: second.instanceId,
          storeAvailable: second.storeAvailable,
          orderCustomerPortalUrl: url.join('.')
        };
      }),
      shareReplay()
    );
  }

  private toolbarData(): Observable<{currentUser: any, business: any}> {
    return from(this.cas.getToolbarData().then((data) => data));
  }

  private getActualUrl(): string {
    return window.location.href;
  }
}
