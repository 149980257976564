import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AccountSettingGuard } from './core/guards/account-setting.guard';
import { SuccessIntegrationComponent } from './shared/payout-method-dialog/success-integration/success-integration.component';

const routes: Routes = [
  { path: 'store', loadChildren: './store/store.module#StoreModule', canLoad: [AuthGuard, AccountSettingGuard]},
  {
    path: 'missing-account-information',
    loadChildren: './missing-account-information/missing-account-information.module#MissingAccountInformationModule',
    canLoad: [AuthGuard]
  },
  { path: 'stripe/success', component: SuccessIntegrationComponent},
  {
    path: 'main',
    loadChildren: './main/main.module#MainModule',
    canLoad: [AuthGuard]
  },
  { path: '', redirectTo: 'main', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
