import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from '../../../environments/environment';
import { User } from '../../core/services/current-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payout-method-dialog',
  templateUrl: './payout-method-dialog.component.html',
  styleUrls: ['./payout-method-dialog.component.scss']
})
export class PayoutMethodDialogComponent implements OnDestroy {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<PayoutMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      nextUrl: string,
      user: User,
      paymentMethodReturnUrl: string
    }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    const url = `https://connect.stripe.com/express/oauth/authorize?
    redirect_uri=${environment.estoreUrl}/stripe/success
    &client_id=${environment.stripe.clientId}
    &state=${this.data.nextUrl}
    &scope=read_write
    &stripe_user[business_type]=company
    &stripe_user[business_name]=${this.data.user.currentBusiness.name}
    &stripe_user[first_name]=${this.data.user.firstName}
    &stripe_user[last_name]=${this.data.user.lastName}
    `;


    window.location.href = url.replace(/\s/g, '');
  }

  ngOnDestroy(): void {
    this.router.navigate([this.data.paymentMethodReturnUrl]);
  }
}
